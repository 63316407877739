import React from "react";
import { ReactComponent as IconTwitter } from "./assets/icons/twitter.svg";
import "./App.css";

class App extends React.Component {
  render = () => {
    return (
      <div className="card">
        <div className="header">
          <div className="logo">
            <img
              src="https://image.jimcdn.com/app/cms/image/transf/dimension=320x1024:format=png/path/sc9b37d19f697a2b9/image/ia0b43359fa8f96c6/version/1689539577/image.png"
              alt="Logo"
              height={"70px"}
            />
          </div>
          <div className="social">
            <a
              href="https://twitter.com/icebeasts"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IconTwitter className="icon" />
            </a>
          </div>
        </div>
        <div className="content">
          <div className="title-holder">
            <h1>Coming soon.</h1>
            <p>
              Please check back to know more. Shoot us an email if you're
              curious.
            </p>
          </div>
          <a href="mailto:info@icebeasts.de">
            <div className="cta">Send us an email</div>
          </a>
        </div>
      </div>
    );
  };
}

export default App;
